import { useCallback } from '@web-stories-wp/react';
import { resourceList } from '@web-stories-wp/media';

import useLibrary from '@src/web-stories-wp/story-editor/src/components/library/useLibrary';
import { useAPI } from '@src/web-stories-wp/story-editor/src/app/api';
import { useStory } from '@src/web-stories-wp/story-editor/src/app';
import { useSnackbar } from '@web-stories-wp/design-system';
import { preloadImage } from '@web-stories-wp/media';

function useInsertLibraryMediaElement() {
  const { insertElement } = useLibrary((state) => ({
    insertElement: state.actions.insertElement,
  }));

  const { updateElementsByResourceId, deleteElementsByResourceId } = useStory(
    ({
      actions: { updateElementsByResourceId, deleteElementsByResourceId },
    }) => ({
      updateElementsByResourceId,
      deleteElementsByResourceId,
    })
  );

  const {
    actions: { download3pAsset },
  } = useAPI();

  const { showSnackbar } = useSnackbar();

  const startDownloadingResource = useCallback(
    async (resource) => {
      try {
        resource.loading = true;

        const assetId = resource.sizes.full.source_url;

        const { url } = await download3pAsset(resource.mediaProviderId, {
          assetId,
        });

        const updatedResource = resource;

        updatedResource.src = url;
        updatedResource.sizes.full.requestFullUrl = false;
        updatedResource.sizes.full.source_url = url;

        updateElementsByResourceId({
          id: resource.id,
          properties: () => {
            return {
              resource: updatedResource,
            };
          },
        });

        await preloadImage(url, '');

        resourceList.set(resource.id, {
          type: 'fullsize',
        });

        updatedResource.loading = false;
        updatedResource.sizes = {};

        updateElementsByResourceId({
          id: resource.id,
          properties: { resource: updatedResource },
        });
      } catch (ex) {
        deleteElementsByResourceId({ id: resource.id });
        showSnackbar({
          message: 'Failed to download an asset.',
          dismissable: true,
        });
      }
    },
    [
      deleteElementsByResourceId,
      download3pAsset,
      showSnackbar,
      updateElementsByResourceId,
    ]
  );

  const insertDownloadableAsset = useCallback(
    async (element) => {
      const { resource } = element;
      resource.loading = true;
      insertElement(resource.type, element);
      await startDownloadingResource(element.resource);
    },
    [insertElement, startDownloadingResource]
  );

  const insertResource = useCallback(
    (resource, thumbnailURL) => {
      resourceList.set(resource.id, {
        url: thumbnailURL,
        type: 'cached',
      });

      if (
        resource.sizes.full?.requestFullUrl &&
        !!resource.sizes.full?.source_url
      ) {
        insertDownloadableAsset({ resource });
      } else {
        insertElement(resource.type, { resource });
      }
    },
    [insertElement, insertDownloadableAsset]
  );

  const insertImage = useCallback(
    (element) => {
      const { resource } = element;

      if (
        resource.sizes.full?.requestFullUrl &&
        !!resource.sizes.full?.source_url
      ) {
        insertDownloadableAsset(element);
      } else {
        insertElement(resource.type, element);
      }
    },
    [insertElement, insertDownloadableAsset]
  );

  const insertMediaElement = useCallback(
    (element) => {
      const { resource } = element;
      const type = element.type ?? resource?.type;
      if (type === 'image') {
        insertImage(element);
      } else {
        insertElement(type, element);
      }
    },
    [insertElement, insertImage]
  );

  return { insertResource, insertMediaElement, startDownloadingResource };
}

export default useInsertLibraryMediaElement;
